<template>
  <EntitySingle
    :config="config"
    :entity.sync="entity"
    :loaded.sync="entityLoaded"
    :extra-validation="extraValidation"
    :extra-actions="extraActions"
  >
    <b-card v-if="entityLoaded">
      <b-tabs pills>
        <!-- Tab: Account -->
        <b-tab active>
          <template #title>
            <feather-icon icon="UserIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">{{ $t('Account') }}</span>
          </template>
          <EntityEditSection
            :module="config.module"
            :entity.sync="entity"
            :form-fields="accountFormFields"
            allow-autofocus
          />
        </b-tab>

        <!-- Tab: Information -->
        <b-tab>
          <template #title>
            <feather-icon icon="InfoIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">{{ $t('Information') }}</span>
          </template>
          <EntityEditSection
            :module="config.module"
            :entity.sync="entity"
            :form-fields="personalFormFields"
          />
        </b-tab>
      </b-tabs>
    </b-card>
  </EntitySingle>
</template>

<script>
import { BTab, BTabs, BCard } from 'bootstrap-vue'
import { translationUtils } from '@core/mixins/ui/translations'
import EntityEditSection from '@/layouts/entity/EntityEditSection.vue'
import EntitySingle from '@/layouts/entity/EntitySingle.vue'

import storeModule from '../userStoreModule'
// import UserEditTabInformation from './UserEditTabInformation.vue'
// import UserEditTabSocial from './UserEditTabSocial.vue'

export default {
  components: {
    EntityEditSection,
    EntitySingle,
    BTab,
    BTabs,
    BCard,
    // UserEditTabInformation,
    // UserEditTabSocial,
  },
  mixins: [translationUtils],
  data() {
    return {
      entity: {
        participant: {},
        activeSubscription: {        
          userGroup: {},
        },
        userGroup: {},
        activeUserGroup: {},
        activeDealSubscription: {},
        enabled: true,
        country: { id: 263, title: 'Greece' },
      },
      entityLoaded: false,
      countryOptions: [],
      companyOptions: [],
      userGroupOptions: [],
    }
  },
  computed: {
    config() {
      return {
        store: storeModule,
        module: 'users',
        endpoint: 'users',
        route: 'users',
        title: {
          single: this.$t('User'),
          plural: this.$t('Users'),
        },
      }
    },
  //   activeSubscriptionTitle() {
  //   return this.entity.activeSubscription.userGroup.title;
  // },
    accountFormFields() {
      const fields = [
        {
          id: 'profilePhoto',
          parent: 'participant',
          type: 'cropupload',
          label: `${this.$t('Featured Image')} (198x198)`,
          colSize: 12,
          width: 198,
          height: 198,
          autoupload: true
        },
        {
          id: 'firstName',
          parent: 'participant',
          type: 'text',
          label: this.$t('First Name'),
          required: true,
          rules: 'min:3',
          colSize: 3,
        },
        {
          id: 'lastName',
          parent: 'participant',
          type: 'text',
          label: this.$t('Last Name'),
          required: true,
          rules: 'min:3',
          colSize: 3,
        },
        {
          id: 'email',
          parent: 'participant',
          type: 'email',
          label: this.$t('email'),
          required: true,
          rules: 'email',
          colSize: 3,
        },
        {
          id: 'company',
          object: true,
          type: 'select',
          label: this.$t('Company'),
          options: this.companyOptions,
          required: true,
          colSize: 3,
        },
      
        {
          id: 'country',
          object: true,
          parent: 'participant',
          type: 'select',
          label: this.$t('country'),
          options: this.countryOptions,
          required: true,
          colSize: 3,
        },
        
      ]
      if(!this.entity.activeUserGroup) {
        fields.push (
          {
          id: 'userGroup',
          object: true,
          type: 'select',
          label: this.$t('User Group'),
          options: this.userGroupOptions,
          required: true,
          colSize: 3,
        },
        // {
        //   id: 'title',
        //   parent: 'activeUserGroup',
        //   object: true,
        //   type: 'text',
        //   label: this.$t('Active User Group'),
        //   required: true,
        //   colSize: 3,
        //   // placeholderVal: this.activeSubscriptionTitle,
        //   // placeholder: this.activeSubscriptionTitle,
        // },
        )

      } else {
        fields.push(
          {
          id: 'activeUserGroup',
          object: true,
          // parent: 'activeUserGroup',
          type: 'select',
          label: this.$t('User Group'),
          options: this.userGroupOptions,
          required: true,
          colSize: 3,
          readonly: true,
        },
        // {
        //   id: 'title',
        //   parent: 'activeUserGroup',
        //   object: true,
        //   type: 'text',
        //   label: this.$t('Active User Group'),
        //   required: true,
        //   colSize: 3,
        //   // placeholderVal: this.activeSubscriptionTitle,
        //   // placeholder: this.activeSubscriptionTitle,
        // },
        )
      }
      fields.push(
        {
          id: 'enabled',
          type: 'checkbox',
          label: this.$t('Enabled'),
          colSize: 1,
        },
        {
          id: 'changePassword',
          type: 'checkbox',
          label: this.$t('change password'),
          hidden: !this.entity.id,
          colSize: 2,
        },
        {
          id: 'password',
          type: 'password',
          label: this.$t('password'),
          required: !this.entity.id || this.entity.changePassword,
          rules: 'password',
          colSize: 3,
          dependency: this.entity.id ? 'changePassword' : null,
        },
      )
      return fields
    },
    personalFormFields() {
      return [
        {
          id: 'city',
          parent: 'participant',
          type: 'text',
          label: this.$t('city'),
          required: false,
          colSize: 3,
        },
        {
          id: 'address',
          parent: 'participant',
          type: 'text',
          label: this.$t('address'),
          required: false,
          colSize: 3,
        },
        {
          id: 'postalCode',
          parent: 'participant',
          type: 'text',
          label: this.$t('postal code'),
          required: false,
          colSize: 3,
        },
        {
          id: 'telephone',
          parent: 'participant',
          type: 'text',
          label: this.$t('telephone'),
          rules: 'phone',
          colSize: 3,
        },
        {
          id: 'mobile',
          parent: 'participant',
          type: 'text',
          label: this.$t('mobile'),
          rules: 'phone',
          required: true,
          colSize: 3,
        },
        {
          id: 'birthDate',
          parent: 'participant',
          type: 'date',
          label: this.$t('birth date'),
          colSize: 3,
        },
        {
          id: 'professionalTitle',
          parent: 'participant',
          type: 'text',
          label: this.$t('professional title'),
          required: false,
          colSize: 3,
        },
        {
          id: 'companyName',
          parent: 'participant',
          type: 'text',
          label: this.$t('company name'),
          colSize: 3,
        },
        {
          id: 'vatId',
          parent: 'participant',
          type: 'text',
          label: this.$t('vat'),
          required: false,
          colSize: 3,
        },
        {
          id: 'vatOffice',
          parent: 'participant',
          type: 'text',
          label: this.$t('vat office'),
          required: false,
          colSize: 3,
        },
        {
          id: 'bio',
          parent: 'participant',
          type: 'textarea',
          label: this.$t('bio'),
          required: false,
          colSize: 6,
        },
        {
          id: 'map',
          type: 'map',
          parent: 'participant',
          colSize: 12,
        },
      ]
    },
    extraActions() {
      if (this.entity.id) {
        return [
          {
            endpoint: 'reset-password/email',
            icon: 'SendIcon',
            title: 'Reset Password Email',
            renderCondition: {
              key: 'enabled',
              value: true,
            },
          },
        ]
      }
      return []
    },
  },
  mounted() {
    this.$store.dispatch('app-common/fetchCountries').then(response => {
      this.countryOptions = response.data
    })
    this.$store.dispatch('app-common/fetchCompanies').then(response => {
      this.companyOptions = response.data
    })
    const queryParams = {
        category: "PRODUCT"
    };

    this.$store.dispatch('app-common/fetchUserGroups', queryParams).then(response => {
      this.userGroupOptions = response.data
    })
  },
  methods: {
    extraValidation() {
      if (this.entity.id && !this.entity.changePassword) {
        delete this.entity.password
      }
      return ''
    },
  },
}
</script>
